// Generated by Framer (e1a5221)

import { addFonts, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["yrcFFyKcu"];

const serializationHash = "framer-T5DQX"

const variantClassNames = {yrcFFyKcu: "framer-v-1vrgr2u"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "yrcFFyKcu", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1vrgr2u", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"yrcFFyKcu"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-1gusi3s"} data-framer-name={"Text input container"} layout={"position"} layoutDependency={layoutDependency} layoutId={"Ia8gYHDLr"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 939 152\"><g id=\"ss1158688479_1\"><path d=\"M 0 152 L 0 0 L 940 0 L 940 152 Z\" fill=\"transparent\"></path><g><defs><linearGradient id=\"idss1158688479_3g-828958455\" x1=\"0.4979078006783164\" x2=\"0.5020921993216837\" y1=\"0\" y2=\"1\"><stop offset=\"0\" stop-color=\"rgba(239,68,59,0.64)\" stop-opacity=\"0.64\"></stop><stop offset=\"1\" stop-color=\"rgba(255,255,255,0)\" stop-opacity=\"0\"></stop></linearGradient></defs><path d=\"M 376 71.192 L 188 115 L 0 115 L 0 152 L 940 152 L 940 1 L 752 1 L 564 71.192 Z\" fill=\"url(#idss1158688479_3g-828958455)\"></path></g><path d=\"M 751.423 0 L 940 0 L 940 2 L 752.577 2 L 564.577 72.192 L 376.381 72.192 L 188.381 116 L 0 116 L 0 114 L 187.619 114 L 375.619 70.192 L 563.423 70.192 Z\" fill=\"rgb(166,172,194)\"></path></g></svg>"} svgContentId={1158688479} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-T5DQX.framer-qa91v6, .framer-T5DQX .framer-qa91v6 { display: block; }", ".framer-T5DQX.framer-1vrgr2u { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-T5DQX .framer-1gusi3s { flex: none; height: 152px; position: relative; width: 939px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-T5DQX.framer-1vrgr2u { gap: 0px; } .framer-T5DQX.framer-1vrgr2u > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-T5DQX.framer-1vrgr2u > :first-child { margin-left: 0px; } .framer-T5DQX.framer-1vrgr2u > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 152
 * @framerIntrinsicWidth 939
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramertFq1so5cE: React.ComponentType<Props> = withCSS(Component, css, "framer-T5DQX") as typeof Component;
export default FramertFq1so5cE;

FramertFq1so5cE.displayName = "Text input container";

FramertFq1so5cE.defaultProps = {height: 152, width: 939};

addFonts(FramertFq1so5cE, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})